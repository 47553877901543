import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'navOption'
  ]
  
  connect() {
    this.scrollToOption()
  }

  scrollToOption() {
    let currentOption = this.navOptionTargets.find(element => element.classList.contains('current'));
    currentOption.scrollIntoView();
  }
}
