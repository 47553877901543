import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'collapsibleContent',
    'collapseContent',
    'expandContent'
  ]
  
  hiddenClass = 'hide'
  
  toggle() {
    this.collapsibleContentTarget.classList.toggle(this.hiddenClass)
    this.collapseContentTarget.classList.toggle(this.hiddenClass)
    this.expandContentTarget.classList.toggle(this.hiddenClass)
  };
  
}
