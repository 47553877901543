import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'collapsibleContent',
    'collapsibleIcon'
  ]
  
  expandedClass = 'sidenav--expanded'
  collapsedCollapsibleClass = 'collapsible__content--collapsed'

  toggle() {
    this.element.classList.toggle(this.expandedClass)
    
    if(this.isCollapsibleExpanded()) {
      this.collapsibleContentTarget.classList.add(this.collapsedCollapsibleClass)
      
    }
  };
  
  openSidenavIfNeeded() {
    if(!this.isSidenavOpen()) {
      this.element.classList.add(this.expandedClass)
    }
  }
  
  toggleCollapsibleIfPossible() {
    if(this.isSidenavOpen()) {
      this.collapsibleContentTarget.classList.toggle(this.collapsedCollapsibleClass)
      this.updateCollapsibleIcon()
    }
  }
  
  isSidenavOpen() {
    return this.element.classList.contains(this.expandedClass)
  }
  
  isCollapsibleExpanded() {
    return this.hasCollapsibleContentTarget && !this.collapsibleContentTarget.classList.contains(this.collapsedCollapsibleClass)
  }
  
  updateCollapsibleIcon() {
    const iconToChangeTo = this.isCollapsibleExpanded() ? 'chevron-up' : 'chevron-down' 
    this.collapsibleIconTarget.innerHTML = feather.icons[iconToChangeTo].contents
  }
}
