import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    "submit",
    "option"
  ]
  static values = {
    hasVoted: Boolean,
    currentVote: String,
    isOpen: Boolean
  }
  updateButton(newValue, hasVoted, currentVote) {
    if (hasVoted && newValue !== currentVote) {
      this.submitTarget.value = "Update Vote"
    } else {
      this.submitTarget.value = "Vote Submitted"
    }
  };

  updateOption(currentTarget, option) {
    if (option === currentTarget) {
      currentTarget.classList.add("checked")
    } else {
      option.classList.remove("checked")
    }
    
  };
  
  updateForm (event) {
    const options = this.optionTargets;
    const hasVoted = this.hasVotedValue;
    const currentVote = this.currentVoteValue;
    if (this.isOpenValue) {
      options.forEach( option => this.updateOption(event.currentTarget, option));
      if (this.hasSubmitTarget) {
          this.updateButton(event.currentTarget.querySelector('input').value, hasVoted, currentVote)
      }
    }
  };
}
