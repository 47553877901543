import Plotly from 'plotly.js-dist'
import { Controller } from "stimulus"

export default class extends Controller {
  static values = {
    gasProduction: Array,
    oilProduction: Array,
    waterProduction: Array,
    firstProductionDate: String,
    lastProductionDate: String
  }

  connect() {
    const gasTrace = this.buildProductionTrace('MCF', this.gasProductionValue, 'rgb(75,215,58)')
    const oilTrace = this.buildProductionTrace('BBL', this.oilProductionValue, 'rgb(187,32,53)')
    const waterTrace = this.buildProductionTrace('Water', this.waterProductionValue, 'rgb(55, 128, 191)')

    const data = [
      gasTrace,
      oilTrace,
      waterTrace
    ]

    const mobile = window.innerWidth <= 428
    
    const layout = {
      title: {
        text: `<b>From:</b> ${this.formatDateForTitle(this.firstProductionDateValue)} <br><b>To:</b> ${this.formatDateForTitle(this.lastProductionDateValue)}`,
        font: {
          size: (mobile ? 12 : 16),
          family: 'Inter, sans-serif',
        },
        x: 0.1,
      },
      yaxis: {
        type: 'log',
        autorange: true,
        showticklabels: 'first',
        dtick: '1',
        gridcolor: '#CCC',
        minor: {
          showgrid: true,
          gridcolor: '#f8f8f8'
        }
      },
      xaxis: {
        type: 'date',
        tickformat: "%b %y",
        tick0: this.firstProductionDateValue,
        dtick: (mobile ? 'M12' : 'M6'),
        tickangle: 45,
        showgrid: false
      },
      margin: {
        l: 60,
        r: 60
      },
      legend: {
        orientation: 'h',
        yanchor: 'bottom',
        xanchor: 'center',
        y: (mobile ? -1 : -0.2),
        x: 0.5
      }
    }
    
    const config = {
      responsive: true,
      scrollZoom: false,
      editable: false,
      displayModeBar: true
    }

    Plotly.newPlot(this.element, data, layout, config)
  }

  buildProductionTrace(name, productions, color) {
    const xValues = productions.map(production => production[0])
    const yValues = this.removeZeroValues(productions.map(production => production[1]))
      
    return {
      type: 'scatter',
      x: xValues,
      y: yValues,
      mode: 'lines',
      name,
      line: {
        color,
        width: 2,
        shape: 'linear'
      },
      connectgaps: false
    }
  }

  formatDateForTitle(date) {
    return new Date(date).toLocaleDateString('en-us', { weekday:"long", year:"numeric", month:"long", day:"numeric", timeZone: 'UTC' })
  }

  removeZeroValues(productionList) {
    return productionList.map(productionValue => productionValue === 0 ? null : productionValue)
  }
}
