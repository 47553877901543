import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'collapsibleContent',
    'hideableContent',
    'toggleHideableContent',
    'topnav',
    'collapsibleIcon'
  ]
  
  collapsedCollapsibleClass = 'collapsible__content--collapsed'
  hiddenClass = 'hide'
  expanded = 'expanded'

  toggleMobile() {
    this.toggleHideableContentTargets.forEach(element => element.classList.toggle(this.hiddenClass));
    this.hideableContentTarget.classList.toggle(this.hiddenClass)
    this.topnavTarget.classList.toggle(this.expanded)
  };
  
  toggleCollapsible() {
    this.collapsibleContentTarget.classList.toggle(this.collapsedCollapsibleClass)
    this.updateCollapsibleIcon()
  }
  
  isCollapsibleExpanded() {
    return this.hasCollapsibleContentTarget && !this.collapsibleContentTarget.classList.contains(this.collapsedCollapsibleClass)
  }

  updateCollapsibleIcon() {
    const iconToChangeTo = this.isCollapsibleExpanded() ? 'chevron-up' : 'chevron-down'
    this.collapsibleIconTarget.innerHTML = feather.icons[iconToChangeTo].contents
  }
}
