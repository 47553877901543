import Rails from "@rails/ujs"
import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [
    'wellSelectForm'
  ]

  changeAsset() {
    Rails.fire(this.wellSelectFormTarget, 'submit')
  };
}
