import { Controller } from "@hotwired/stimulus"
export default class extends Controller {
  submitForm(event) {
    let isValid = this.validateForm(this.element);
    if (!isValid) {
      event.preventDefault();
    } else {
      this.element.requestSubmit()
    }
  }
  
  removeError(event) {
    if (event.target.classList.contains("error") && event.target.value.trim()) {
      event.target.classList.remove('error')
      this.element.querySelector('.button-secondary').disabled = false
      event.target.nextElementSibling.remove();
    }
  }

  validateForm(element) {
    let isValid = true;
    let requiredFieldSelectors = 'textarea:required, input:required';
    let requiredFields = element.querySelectorAll(requiredFieldSelectors);
    requiredFields.forEach((field) => {
      if (!field.disabled && !field.value.trim()) {
        isValid = false;
        field.classList.add("error");
        const div = field.parentElement;
        const para = document.createElement("p");
        const node = document.createTextNode("Required");
        para.appendChild(node);
        para.classList.add("error")
        div.appendChild(para);
      } 
    });
    return isValid;
  }
}
